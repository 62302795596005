import * as i0 from '@angular/core';
import { Directive, Input, NgZone, ElementRef, Injectable, Inject, TemplateRef, Component, ChangeDetectionStrategy, ContentChildren, ContentChild, NgModule } from '@angular/core';
import { MutationObserverService, MUTATION_OBSERVER_INIT } from '@ng-web-apis/mutation-observer';
import * as i2 from '@taiga-ui/cdk';
import { AbstractTuiController, tuiZoneOptimized, tuiClamp, TuiResizeService, EMPTY_QUERY, TuiDestroyService, TuiItemDirective, TuiItemModule, TuiLetModule } from '@taiga-ui/cdk';
import * as i1 from 'rxjs';
import { Observable, merge } from 'rxjs';
import { throttleTime, map, distinctUntilChanged, share } from 'rxjs/operators';
import * as i1$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@taiga-ui/kit/directives';
import { TuiProjectClassModule } from '@taiga-ui/kit/directives';
const _c0 = () => ["_active"];
const _c1 = a0 => ({
  $implicit: a0
});
function TuiItemsWithMoreComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiItemsWithMoreComponent_ng_container_2_div_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiItemsWithMoreComponent_ng_container_2_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtemplate(1, TuiItemsWithMoreComponent_ng_container_2_div_1_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const index_r2 = ctx.index;
    const lastIndex_r3 = i0.ɵɵnextContext().tuiLet;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-item_hidden", index_r2 > lastIndex_r3 && index_r2 !== ctx_r3.directive.required);
    i0.ɵɵproperty("tuiProjectClass", i0.ɵɵpureFunction0(4, _c0));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", item_r1);
  }
}
function TuiItemsWithMoreComponent_ng_container_2_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵelementContainer(1, 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const lastIndex_r3 = i0.ɵɵnextContext().tuiLet;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-item_hidden", lastIndex_r3 >= ctx_r3.items.length - 1);
    i0.ɵɵproperty("tuiProjectClass", i0.ɵɵpureFunction0(5, _c0));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r3.more)("ngTemplateOutletContext", i0.ɵɵpureFunction1(6, _c1, lastIndex_r3));
  }
}
function TuiItemsWithMoreComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiItemsWithMoreComponent_ng_container_2_div_1_Template, 2, 5, "div", 2)(2, TuiItemsWithMoreComponent_ng_container_2_span_2_Template, 2, 8, "span", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const lastIndex_r3 = ctx.tuiLet;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r3.items);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r3.more && lastIndex_r3 < ctx_r3.items.length - 1);
  }
}
class TuiItemsWithMoreDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.itemsLimit = Infinity;
    this.required = -1;
  }
}
TuiItemsWithMoreDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiItemsWithMoreDirective_BaseFactory;
  return function TuiItemsWithMoreDirective_Factory(t) {
    return (ɵTuiItemsWithMoreDirective_BaseFactory || (ɵTuiItemsWithMoreDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiItemsWithMoreDirective)))(t || TuiItemsWithMoreDirective);
  };
})();
TuiItemsWithMoreDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiItemsWithMoreDirective,
  selectors: [["tui-items-with-more"]],
  inputs: {
    itemsLimit: "itemsLimit",
    required: "required"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemsWithMoreDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-items-with-more'
    }]
  }], null, {
    itemsLimit: [{
      type: Input
    }],
    required: [{
      type: Input
    }]
  });
})();
class TuiItemsWithMoreService extends Observable {
  constructor(zone, el, mutation$, resize$, directive) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.zone = zone;
    this.el = el;
    this.mutation$ = mutation$;
    this.resize$ = resize$;
    this.directive = directive;
    this.stream$ = merge(this.directive.change$, this.mutation$, this.resize$).pipe(throttleTime(0), map(() => this.getOverflowIndex()), distinctUntilChanged(), tuiZoneOptimized(this.zone), share());
  }
  getOverflowIndex() {
    var _a;
    const {
      clientWidth,
      children
    } = this.el.nativeElement;
    const items = Array.from(children, ({
      clientWidth
    }) => clientWidth);
    const first = this.directive.required === -1 ? 0 : this.directive.required;
    const last = items.length - 1;
    const more = ((_a = children[last]) === null || _a === void 0 ? void 0 : _a.tagName) === 'SPAN' ? items[last] : 0;
    items.unshift(...items.splice(first, 1));
    let total = items.reduce((sum, width) => sum + width, 0) - more;
    if (total <= clientWidth && this.directive.itemsLimit >= items.length) {
      return this.maxItems;
    }
    for (let i = last - 1; i > 0; i--) {
      total -= items[i];
      if (total + more <= clientWidth) {
        return tuiClamp(i > this.directive.required ? i - 1 : i - 2, -1, this.maxItems);
      }
    }
    return -1;
  }
  get maxItems() {
    return this.directive.itemsLimit > this.directive.required ? this.directive.itemsLimit - 1 : this.directive.itemsLimit - 2;
  }
}
TuiItemsWithMoreService.ɵfac = function TuiItemsWithMoreService_Factory(t) {
  return new (t || TuiItemsWithMoreService)(i0.ɵɵinject(NgZone), i0.ɵɵinject(ElementRef), i0.ɵɵinject(MutationObserverService), i0.ɵɵinject(TuiResizeService), i0.ɵɵinject(TuiItemsWithMoreDirective));
};
TuiItemsWithMoreService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiItemsWithMoreService,
  factory: TuiItemsWithMoreService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemsWithMoreService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [MutationObserverService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiResizeService]
      }]
    }, {
      type: TuiItemsWithMoreDirective,
      decorators: [{
        type: Inject,
        args: [TuiItemsWithMoreDirective]
      }]
    }];
  }, null);
})();
class TuiMoreDirective {
  static ngTemplateContextGuard(_dir, _ctx) {
    return true;
  }
}
TuiMoreDirective.ɵfac = function TuiMoreDirective_Factory(t) {
  return new (t || TuiMoreDirective)();
};
TuiMoreDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiMoreDirective,
  selectors: [["", "tuiMore", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMoreDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiMore]'
    }]
  }], null, null);
})();
class TuiItemsWithMoreComponent {
  constructor(directive, lastVisibleIndex$) {
    this.directive = directive;
    this.lastVisibleIndex$ = lastVisibleIndex$;
    this.items = EMPTY_QUERY;
  }
}
TuiItemsWithMoreComponent.ɵfac = function TuiItemsWithMoreComponent_Factory(t) {
  return new (t || TuiItemsWithMoreComponent)(i0.ɵɵdirectiveInject(TuiItemsWithMoreDirective), i0.ɵɵdirectiveInject(TuiItemsWithMoreService));
};
TuiItemsWithMoreComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiItemsWithMoreComponent,
  selectors: [["tui-items-with-more"]],
  contentQueries: function TuiItemsWithMoreComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiMoreDirective, 5, TemplateRef);
      i0.ɵɵcontentQuery(dirIndex, TuiItemDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.more = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.items = _t);
    }
  },
  features: [i0.ɵɵProvidersFeature([MutationObserverService, TuiResizeService, TuiDestroyService, TuiItemsWithMoreService, {
    provide: MUTATION_OBSERVER_INIT,
    useValue: {
      childList: true,
      characterData: true,
      subtree: true
    }
  }])],
  decls: 4,
  vars: 6,
  consts: [[4, "ngIf"], [4, "tuiLet"], ["class", "t-item", 3, "t-item_hidden", "tuiProjectClass", 4, "ngFor", "ngForOf"], ["class", "t-item", 3, "t-item_hidden", "tuiProjectClass", 4, "ngIf"], [1, "t-item", 3, "tuiProjectClass"], [4, "ngTemplateOutlet"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
  template: function TuiItemsWithMoreComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiItemsWithMoreComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵtemplate(2, TuiItemsWithMoreComponent_ng_container_2_Template, 3, 2, "ng-container", 1);
      i0.ɵɵpipe(3, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.items.changes));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(3, 4, ctx.lastVisibleIndex$) || 0);
    }
  },
  dependencies: [i1$1.NgIf, i2.TuiLetDirective, i1$1.NgForOf, i3.TuiProjectClassDirective, i1$1.NgTemplateOutlet, i1$1.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:relative;display:flex;align-items:center;white-space:nowrap}.t-item[_ngcontent-%COMP%]{flex:0 0 auto}.t-item_hidden[_ngcontent-%COMP%]{position:absolute;visibility:hidden}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemsWithMoreComponent, [{
    type: Component,
    args: [{
      selector: 'tui-items-with-more',
      templateUrl: './items-with-more.template.html',
      styleUrls: ['./items-with-more.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [MutationObserverService, TuiResizeService, TuiDestroyService, TuiItemsWithMoreService, {
        provide: MUTATION_OBSERVER_INIT,
        useValue: {
          childList: true,
          characterData: true,
          subtree: true
        }
      }]
    }]
  }], function () {
    return [{
      type: TuiItemsWithMoreDirective,
      decorators: [{
        type: Inject,
        args: [TuiItemsWithMoreDirective]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiItemsWithMoreService]
      }]
    }];
  }, {
    items: [{
      type: ContentChildren,
      args: [TuiItemDirective, {
        read: TemplateRef,
        descendants: true
      }]
    }],
    more: [{
      type: ContentChild,
      args: [TuiMoreDirective, {
        read: TemplateRef
      }]
    }]
  });
})();
class TuiItemsWithMoreModule {}
TuiItemsWithMoreModule.ɵfac = function TuiItemsWithMoreModule_Factory(t) {
  return new (t || TuiItemsWithMoreModule)();
};
TuiItemsWithMoreModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiItemsWithMoreModule
});
TuiItemsWithMoreModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiItemModule, TuiLetModule, TuiProjectClassModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiItemsWithMoreModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiItemModule, TuiLetModule, TuiProjectClassModule],
      declarations: [TuiItemsWithMoreComponent, TuiItemsWithMoreDirective, TuiMoreDirective],
      exports: [TuiItemsWithMoreComponent, TuiItemsWithMoreDirective, TuiMoreDirective, TuiItemDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiItemsWithMoreComponent, TuiItemsWithMoreDirective, TuiItemsWithMoreModule, TuiItemsWithMoreService, TuiMoreDirective };

import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Input, NgModule } from '@angular/core';

/**
 * A directive for projecting classes from nested children to host
 */
class TuiProjectClassDirective {
  constructor(el) {
    this.el = el;
    this.classNames = [];
  }
  ngAfterViewChecked() {
    this.classNames.forEach(className => {
      const hostElement = this.el.nativeElement;
      hostElement.classList.toggle(className, !!hostElement.querySelector(`.${className}`));
    });
  }
}
TuiProjectClassDirective.ɵfac = function TuiProjectClassDirective_Factory(t) {
  return new (t || TuiProjectClassDirective)(i0.ɵɵdirectiveInject(ElementRef));
};
TuiProjectClassDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiProjectClassDirective,
  selectors: [["", "tuiProjectClass", ""]],
  inputs: {
    classNames: [0, "tuiProjectClass", "classNames"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiProjectClassDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiProjectClass]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, {
    classNames: [{
      type: Input,
      args: ['tuiProjectClass']
    }]
  });
})();
class TuiProjectClassModule {}
TuiProjectClassModule.ɵfac = function TuiProjectClassModule_Factory(t) {
  return new (t || TuiProjectClassModule)();
};
TuiProjectClassModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiProjectClassModule
});
TuiProjectClassModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiProjectClassModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiProjectClassDirective],
      exports: [TuiProjectClassDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiProjectClassDirective, TuiProjectClassModule };
